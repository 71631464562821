<template>
  <div>
    <h2>Bejelentkezés</h2>
    <form @submit.prevent="login">
      <input type="text" v-model="email" placeholder="Email" />
      <input type="password" v-model="password" placeholder="Jelszó" />
      <button type="submit">Bejelentkezés</button>
      <p>
        Még nincs fiókod?
        <router-link to="/register">Regisztrálj itt</router-link>
      </p>
      <p>
        <router-link to="/reset-password">Elfeljetett jelszó</router-link>
      </p>
    </form>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      email: "",
      password: "",
    };
  },
  created() {
    // Ellenőrizzük, hogy a felhasználó már be van-e jelentkezve
    if (localStorage.getItem("token")) {
      this.$router.push("/"); // Navigáció a főoldalra, ha már be van jelentkezve
    }
  },
  methods: {
    async login() {
      try {
        const response = await axios.post(
          "https://rcon.octagent.com/api/index.php",
          {
            action: "login",
            email: this.email,
            password: this.password,
          }
        );

        // Ellenőrizzük, hogy a válasz tartalmaz-e tokent
        if (response.data.status === "Success" && response.data.token) {
          localStorage.setItem("token", response.data.token); // Token tárolása
          this.$router.push("/"); // Navigáció bejelentkezés után
        } else {
          console.error("Bejelentkezés sikertelen:", response.data.message);
          // További hibakezelés
        }
      } catch (error) {
        console.error("Hiba történt a bejelentkezés során:", error);
        // További hibakezelés
      }
    },
  },
};
</script>
