<template>
  <div>
    <p>Kijelentkezés...</p>
  </div>
</template>

<script>
import axios from "axios";

export default {
  created() {
    this.logout();
  },
  methods: {
    async logout() {
      try {
        // Axios hívás a backendhez, hogy a logout action-t végrehajtsuk
        await axios.post("https://rcon.octagent.com/api/index.php", {
          action: "logout",
          token: localStorage.getItem("token"),
        });

        // Token törlése
        if (localStorage.getItem("token")) {
          localStorage.removeItem("token");
        }

        /*if (sessionStorage.getItem("token")) {
          sessionStorage.removeItem("token");
        }*/

        // Átirányítás a bejelentkező oldalra vagy főoldalra
        this.$router.push("/login"); // vagy '/home' attól függően, hogy hova szeretnéd irányítani
      } catch (error) {
        console.error("Hiba történt a kijelentkezés során:", error);
        // Hibakezelés itt
      }
    },
  },
};
</script>
