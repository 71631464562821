<template>
  <div>
    <h2>Regisztráció</h2>
    <form @submit.prevent="register">
      <input type="text" v-model="username" placeholder="Felhasználónév" />
      <input type="email" v-model="email" placeholder="Email" />
      <input type="password" v-model="password" placeholder="Jelszó" />
      <button type="submit">Regisztráció</button>
    </form>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      username: "",
      email: "",
      password: "",
    };
  },
  created() {
    // Ellenőrizzük, hogy a felhasználó már be van-e jelentkezve
    if (localStorage.getItem("token")) {
      this.$router.push("/"); // Navigáció a főoldalra, ha már be van jelentkezve
    }
  },
  methods: {
    async register() {
      try {
        const response = await axios.post(
          "https://rcon.octagent.com/api/index.php",
          {
            action: "register",
            username: this.username,
            email: this.email,
            password: this.password,
          }
        );
        console.log(response.data); // Kezelheted a választ itt
      } catch (error) {
        console.error(error); // Hibakezelés
      }
    },
  },
};
</script>
