<template>
  <div>
    <h2>
      {{ email && token ? "Új jelszó megadása" : "Jelszó visszaállítása" }}
    </h2>
    <form @submit.prevent="handleSubmit">
      <div v-if="!email || !token">
        <input
          type="email"
          v-model="emailInput"
          placeholder="Email cím"
          required
        />
        <button type="submit">Jelszó visszaállítása</button>
      </div>
      <div v-else>
        <input
          type="password"
          v-model="newPassword"
          placeholder="Új jelszó"
          required
        />
        <button type="submit">Jelszó frissítése</button>
      </div>
    </form>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      emailInput: "",
      newPassword: "",
      email: new URLSearchParams(window.location.search).get("email"),
      token: new URLSearchParams(window.location.search).get("token"),
    };
  },
  created() {
    // Ellenőrizzük, hogy a felhasználó már be van-e jelentkezve
    if (localStorage.getItem("token")) {
      this.$router.push("/"); // Navigáció a főoldalra, ha már be van jelentkezve
    }
  },
  methods: {
    async handleSubmit() {
      if (this.email && this.token) {
        // Új jelszó megadása
        try {
          const response = await axios.post(
            "https://rcon.octagent.com/api/index.php",
            {
              action: "update_password",
              email: this.email,
              token: this.token,
              password: this.newPassword,
            }
          );
          console.log(response.data); // Kezelheted a választ itt
          // További lépések, például visszajelzés a felhasználónak
        } catch (error) {
          console.error(error); // Hibakezelés
          // További hibakezelés
        }
      } else {
        // Jelszó visszaállító kérelem
        try {
          const response = await axios.post(
            "https://rcon.octagent.com/api/index.php",
            {
              action: "reset_password",
              email: this.emailInput,
            }
          );
          console.log(response.data); // Kezelheted a választ itt
          // További lépések, például visszajelzés a felhasználónak
        } catch (error) {
          console.error(error); // Hibakezelés
          // További hibakezelés
        }
      }
    },
  },
};
</script>
