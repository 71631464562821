<template>
  <div class="css-h65hj64">
    <header class="css-gth52vv">
      <div class="css-gitr4oh">
        SmartHub<router-link to="/agent">agent</router-link>
      </div>
      <div class="css-gi4kk8d">
        <div class="css-dwso32s">
          <svg
            style="width: 16px; height: 16px; fill: #1774e2; margin-right: 4px"
            viewBox="0 0 24 24"
            focusable="false"
            class="chakra-icon css-4efmk1"
            role="presentation"
          >
            <path
              d="M12,3C6.5,3 2,6.58 2,11C2.05,13.15 3.06,15.17 4.75,16.5C4.75,17.1 4.33,18.67 2,21C4.37,20.89 6.64,20 8.47,18.5C9.61,18.83 10.81,19 12,19C17.5,19 22,15.42 22,11C22,6.58 17.5,3 12,3M12,17C7.58,17 4,14.31 4,11C4,7.69 7.58,5 12,5C16.42,5 20,7.69 20,11C20,14.31 16.42,17 12,17Z"
            ></path>
          </svg>
          <span>Unlimited</span>
        </div>
        <div class="css-rek3mro">
          <svg
            style="width: 16px; height: 16px; fill: #1774e2; margin-right: 4px"
            viewBox="0 0 24 24"
            focusable="false"
            class="chakra-icon css-4efmk1"
            role="presentation"
          >
            <path
              d="M17.5 15.5C17.5 16.61 16.61 17.5 15.5 17.5S13.5 16.61 13.5 15.5 14.4 13.5 15.5 13.5 17.5 14.4 17.5 15.5M8.5 13.5C7.4 13.5 6.5 14.4 6.5 15.5S7.4 17.5 8.5 17.5 10.5 16.61 10.5 15.5 9.61 13.5 8.5 13.5M23 15V18C23 18.55 22.55 19 22 19H21V20C21 21.11 20.11 22 19 22H5C3.9 22 3 21.11 3 20V19H2C1.45 19 1 18.55 1 18V15C1 14.45 1.45 14 2 14H3C3 10.13 6.13 7 10 7H11V5.73C10.4 5.39 10 4.74 10 4C10 2.9 10.9 2 12 2S14 2.9 14 4C14 4.74 13.6 5.39 13 5.73V7H14C17.87 7 21 10.13 21 14H22C22.55 14 23 14.45 23 15M21 16H19V14C19 11.24 16.76 9 14 9H10C7.24 9 5 11.24 5 14V16H3V17H5V20H19V17H21V16Z"
            ></path>
          </svg>
          <span>0 / 1000</span>
        </div>
        <div class="css-gu4rhboe">
          <svg
            style="width: 20px; height: 20px; fill: #444"
            viewBox="0 0 24 24"
            focusable="false"
            class="chakra-icon css-abioxt"
            role="presentation"
            aria-hidden="true"
          >
            <path
              d="M10 21H14C14 22.1 13.1 23 12 23S10 22.1 10 21M21 19V20H3V19L5 17V11C5 7.9 7 5.2 10 4.3V4C10 2.9 10.9 2 12 2S14 2.9 14 4V4.3C17 5.2 19 7.9 19 11V17L21 19M17 11C17 8.2 14.8 6 12 6S7 8.2 7 11V18H17V11Z"
            ></path>
          </svg>
        </div>
      </div>
    </header>
    <div class="css-g5k433d">
      <div class="page-content css-g5hbgnt">
        <div class="css-4kfi39s">
          <div class="css-gdo43fe">
            <p class="css-k3idldo">
              Last step to see live chat on your website!
            </p>
            <p class="css-gt44fo2">
              Install Smartsupp by adding piece of chat code to your website or
              use our integrations.
            </p>
            <button class="btn-primary css-gri3421">Install Smartsupp</button>
            <p class="css-gir38gi">
              Not sure how? We're here for you. Chat with us
            </p>
          </div>
          <div class="css-fegi3j3">
            <img
              src="https://dash-v2.smartsuppcdn.com/assets/illustrations/install-banner.svg"
              class=""
            />
          </div>
        </div>
        <div class="css-1f97miq">
          <div class="css-gcv423g opennnnnnnnnnnnnnnnnnn">
            <div class="accordion-parent css-btru821" @click="toggleAccordion">
              Let's start with the basics
            </div>
            <div class="css-g54z5he" data-width="2"></div>
            <div class="accordion-child css-bztbz65">
              <div class="accordion-row css-zt55b55 checked">
                <div class="accordion-check css-cb5fgnhr4"></div>
                <div class="accordion-text css-bgt5bztr">
                  <div class="css-guow9dk">
                    Customize the chat box to match your website
                  </div>
                  <div class="css-hjz465n4">
                    With a good profile picture, personalized brand colors, and
                    friendly texts, you'll make the right impression right from
                    the start.
                  </div>
                </div>
              </div>
              <div class="accordion-row css-zt55b55">
                <div class="accordion-check css-cb5fgnhr4"></div>
                <div class="accordion-text css-bgt5bztr">
                  <div class="css-guow9dk">
                    Discover how the live chat works
                  </div>
                  <div class="css-hjz465n4">
                    We'll show you how to work with the live chat and you'll be
                    able to attend to your first visitor in no time.
                  </div>
                </div>
              </div>
              <div class="accordion-row css-zt55b55">
                <div class="accordion-check css-cb5fgnhr4"></div>
                <div class="accordion-text css-bgt5bztr">
                  <div class="css-guow9dk">
                    Answer every message fast and with ease
                  </div>
                  <div class="css-hjz465n4">
                    Use automated messages to reassure visitors that you've
                    received their question, and that you will reply to them
                    soon.
                  </div>
                </div>
              </div>
              <div class="accordion-row css-zt55b55">
                <div class="accordion-check css-cb5fgnhr4"></div>
                <div class="accordion-text css-bgt5bztr">
                  <div class="css-guow9dk">
                    Communicate with visitors anytime, anywhere
                  </div>
                  <div class="css-hjz465n4">
                    With our mobile app, you won't miss a single conversation.
                  </div>
                </div>
              </div>
              <div class="accordion-row css-zt55b55">
                <div class="accordion-check css-cb5fgnhr4"></div>
                <div class="accordion-text css-bgt5bztr">
                  <div class="css-guow9dk">
                    Kick off your first conversation
                  </div>
                  <div class="css-hjz465n4">
                    Add Smartsupp to your website by completing the
                    installation, and you're ready to start chatting with your
                    visitors!
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="css-gcv423g">
            <div class="accordion-parent css-btru821" @click="toggleAccordion">
              I'd like to attract new customers
            </div>
            <div class="css-g54z5he"></div>
            <div class="accordion-child css-bztbz65">
              <div class="accordion-row css-zt55b55">
                <div class="accordion-check css-cb5fgnhr4"></div>
                <div class="accordion-text css-bgt5bztr">
                  <div class="css-guow9dk">
                    Engage visitors with targeted messages
                  </div>
                  <div class="css-hjz465n4">
                    Reach your target audience with an offer or message that is
                    interesting to them.
                  </div>
                </div>
              </div>
              <div class="accordion-row css-zt55b55">
                <div class="accordion-check css-cb5fgnhr4"></div>
                <div class="accordion-text css-bgt5bztr">
                  <div class="css-guow9dk">
                    Actively reach out to visitors and advise them on their
                    choice
                  </div>
                  <div class="css-hjz465n4">
                    The Visitor list makes it easy to identify potential
                    customers who are currently browsing your website.
                  </div>
                </div>
              </div>
              <div class="accordion-row css-zt55b55">
                <div class="accordion-check css-cb5fgnhr4"></div>
                <div class="accordion-text css-bgt5bztr">
                  <div class="css-guow9dk">Sell more with a Chatbot</div>
                  <div class="css-hjz465n4">
                    The Chatbot provides customers with key information for
                    their purchase, and guides them to the product(s) they are
                    searching for.
                  </div>
                </div>
              </div>
              <div class="accordion-row css-zt55b55">
                <div class="accordion-check css-cb5fgnhr4"></div>
                <div class="accordion-text css-bgt5bztr">
                  <div class="css-guow9dk">
                    Collect contacts of potential customers
                  </div>
                  <div class="css-hjz465n4">
                    With the Contact form, you can reach customers with a
                    personalized offer, even after they've left your website.
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="css-gcv423g">
            <div class="accordion-parent css-btru821" @click="toggleAccordion">
              I'd like to provide customer support
            </div>
            <div class="css-g54z5he"></div>
            <div class="accordion-child css-bztbz65">
              <div class="accordion-row css-zt55b55">
                <div class="accordion-check css-cb5fgnhr4"></div>
                <div class="accordion-text css-bgt5bztr">
                  <div class="css-guow9dk">
                    Always keep your status in check
                  </div>
                  <div class="css-hjz465n4">
                    Set up when you're available for visitors, and avoid
                    accidentally logging in so that customers don't mistake you
                    for being online.
                  </div>
                </div>
              </div>
              <div class="accordion-row css-zt55b55">
                <div class="accordion-check css-cb5fgnhr4"></div>
                <div class="accordion-text css-bgt5bztr">
                  <div class="css-guow9dk">Reply in seconds with Shortcuts</div>
                  <div class="css-hjz465n4">
                    Shortcuts are preset messages that allow you to send replies
                    in just one click.
                  </div>
                </div>
              </div>
              <div class="accordion-row css-zt55b55">
                <div class="accordion-check css-cb5fgnhr4"></div>
                <div class="accordion-text css-bgt5bztr">
                  <div class="css-guow9dk">
                    Leave repetitive questions to the chatbot
                  </div>
                  <div class="css-hjz465n4">
                    The Chatbot provides customers with quick and accurate
                    answers to their most common questions and problems.
                  </div>
                </div>
              </div>
              <div class="accordion-row css-zt55b55">
                <div class="accordion-check css-cb5fgnhr4"></div>
                <div class="accordion-text css-bgt5bztr">
                  <div class="css-guow9dk">
                    Discover your customers' most frequent problems
                  </div>
                  <div class="css-hjz465n4">
                    Conversations tagging gives you a better idea of the
                    questions you get most often.
                  </div>
                </div>
              </div>
              <div class="accordion-row css-zt55b55">
                <div class="accordion-check css-cb5fgnhr4"></div>
                <div class="accordion-text css-bgt5bztr">
                  <div class="css-guow9dk">
                    Contact visitors even after they've left your website
                  </div>
                  <div class="css-hjz465n4">
                    With the Contact form, you can reply to visitors even if
                    they are no longer in the conversation.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="css-z5h56fhg">
          <div class="css-j7hgft5f">
            <div class="css-5hz5bree">Smartsupp Academy</div>
            <div class="css-h7jh67gg">
              <div class="css-hu6j76bg" @click="slidingArticles">
                <svg
                  viewBox="0 0 24 24"
                  focusable="false"
                  class="chakra-icon css-abioh6xt"
                  role="presentation"
                  aria-hidden="true"
                >
                  <path
                    d="M20,11V13H8L13.5,18.5L12.08,19.92L4.16,12L12.08,4.08L13.5,5.5L8,11H20Z"
                  ></path>
                </svg>
              </div>
              <div class="css-h6h65gv4" @click="slidingArticles">
                <svg
                  viewBox="0 0 24 24"
                  focusable="false"
                  class="chakra-icon css-abindoxt"
                  role="presentation"
                  aria-hidden="true"
                >
                  <path
                    d="M4,11V13H16L10.5,18.5L11.92,19.92L19.84,12L11.92,4.08L10.5,5.5L16,11H4Z"
                  ></path>
                </svg>
              </div>
            </div>
          </div>
          <div class="css-h6h6gvv4">
            <div class="css-u6hgvtrv">
              <div class="css-j76j5h43">
                <div class="css-u78kj7jh">
                  <div class="css-h7j4g54f">1.</div>
                  <div class="css-bz6b5zv4"></div>
                </div>
                <div class="css-h5hg64f5">
                  <div class="css-z6g5frcf">
                    How to Add Smartsupp to Your Website
                  </div>
                  <div class="css-h6hgvtv6">
                    Find out how to set up and get started with Smartsupp after
                    registration–install the chatbot on your site and initiate
                    chats with your website visitors.
                  </div>
                </div>
                <div class="css-h6g54cfg">
                  <div class="css-h76hg5tf">2:05</div>
                  <div class="css-7hg6t5hf">Play</div>
                </div>
              </div>

              <div class="css-j76j5h43">
                <div class="css-u78kj7jh">
                  <div class="css-h7j4g54f">2.</div>
                  <div class="css-h5h5hg4f"></div>
                </div>
                <div class="css-h5hg64f5">
                  <div class="css-z6g5frcf">
                    How to Add Smartsupp to Your Website
                  </div>
                  <div class="css-h6hgvtv6">
                    Find out how to set up and get started with Smartsupp after
                    registration–install the chatbot on your site and initiate
                    chats with your website visitors.
                  </div>
                </div>
                <div class="css-h6g54cfg">
                  <div class="css-h76hg5tf">2:05</div>
                  <div class="css-7hg6t5hf">Play</div>
                </div>
              </div>

              <div class="css-j76j5h43">
                <div class="css-u78kj7jh">
                  <div class="css-h7j4g54f">3.</div>
                  <div class="css-h6v5h5g5"></div>
                </div>
                <div class="css-h5hg64f5">
                  <div class="css-z6g5frcf">
                    How to Add Smartsupp to Your Website
                  </div>
                  <div class="css-h6hgvtv6">
                    Find out how to set up and get started with Smartsupp after
                    registration–install the chatbot on your site and initiate
                    chats with your website visitors.
                  </div>
                </div>
                <div class="css-h6g54cfg">
                  <div class="css-h76hg5tf">2:05</div>
                  <div class="css-7hg6t5hf">Play</div>
                </div>
              </div>

              <div class="css-j76j5h43">
                <div class="css-u78kj7jh">
                  <div class="css-h7j4g54f">4.</div>
                  <div class="css-j7iu6gzf"></div>
                </div>
                <div class="css-h5hg64f5">
                  <div class="css-z6g5frcf">
                    How to Add Smartsupp to Your Website
                  </div>
                  <div class="css-h6hgvtv6">
                    Find out how to set up and get started with Smartsupp after
                    registration–install the chatbot on your site and initiate
                    chats with your website visitors.
                  </div>
                </div>
                <div class="css-h6g54cfg">
                  <div class="css-h76hg5tf">2:05</div>
                  <div class="css-7hg6t5hf">Play</div>
                </div>
              </div>

              <div class="css-j76j5h43">
                <div class="css-u78kj7jh">
                  <div class="css-h7j4g54f">5.</div>
                  <div class="css-hu7hgzft"></div>
                </div>
                <div class="css-h5hg64f5">
                  <div class="css-z6g5frcf">
                    How to Add Smartsupp to Your Website
                  </div>
                  <div class="css-h6hgvtv6">
                    Find out how to set up and get started with Smartsupp after
                    registration–install the chatbot on your site and initiate
                    chats with your website visitors.
                  </div>
                </div>
                <div class="css-h6g54cfg">
                  <div class="css-h76hg5tf">2:05</div>
                  <div class="css-7hg6t5hf">Play</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <h1>Home</h1>
      <form class="row">
        <input
          type="text"
          v-model="task"
          placeholder="Add new task"
          class="column small-7"
        />
        <input
          type="date"
          @blur="addTask"
          v-model="due"
          class="column small-5"
        />
      </form>
      <div class="row">
        <div class="column small-12">
          <div class="row">
            <i
              class="fi-check column small-2"
              @click="toogleCompleted"
              title="Kész feladatok elrejtése / megmutatása"
            ></i>
            <div class="column small-10 text-right">
              <i class="fi-checkbox small-6" title="Nyitott">{{ open }}</i>
              <i class="fi-flag small-6" title="Lejárt">{{
                overdueTasks.length
              }}</i>
            </div>
          </div>
        </div>
      </div>
      <ul>
        <TaskView
          v-for="task in tasks"
          :key="task.id"
          :task="task"
          :showCompleted="showCompleted"
        />
      </ul>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import TaskView from "@/components/TaskView.vue";

export default {
  name: "HomeView",

  components: {
    TaskView,
  },

  data() {
    return {
      showCompleted: true,
      task: "",
      due: new Date(new Date().setDate(new Date().getDate() + 5))
        .toISOString()
        .split("T")[0],
    };
  },

  computed: {
    ...mapGetters(["overdueTasks"]),
    open: function () {
      return this.$store.state.tasks.filter((task) => !task.completed).length;
    },
    tasks: function () {
      return this.$store.state.tasks;
    },
  },

  methods: {
    addTask() {
      const task = { name: this.task, completed: false, due: this.due };
      axios
        .post(process.env.VUE_APP_API_URL, task)
        .then((response) => {
          this.tasks.push(response.data);
          this.task = "";
        })
        .catch((err) => console.log(err));
    },

    toggleAccordion(event) {
      const parent = event.target.closest(".css-gcv423g");

      if (parent) {
        parent.classList.toggle("open");
      }
    },

    slidingArticles(event) {
      const btn_prev = document.querySelector(".css-hu6j76bg");
      const btn_next = document.querySelector(".css-h6h65gv4");
      const slider = document.querySelector(".css-u6hgvtrv");

      const interval = 600;

      // Ellenőrizd a 'left' értéket és konvertáld számra
      const current_property_left_slider =
        parseInt(getComputedStyle(slider).left) || 0;

      let new_pos_left_slider;

      if (event.currentTarget === btn_next) {
        if (
          Math.abs(current_property_left_slider) +
            interval +
            slider.clientWidth >=
          slider.scrollWidth
        ) {
          new_pos_left_slider = -(slider.scrollWidth - slider.clientWidth);
        } else {
          new_pos_left_slider = current_property_left_slider - interval;
        }

        slider.style.left = `${new_pos_left_slider}px`;
      }

      if (event.currentTarget === btn_prev) {
        new_pos_left_slider = current_property_left_slider + interval;
        if (new_pos_left_slider > 0) {
          slider.style.left = "0px";
        } else {
          slider.style.left = `${new_pos_left_slider}px`;
        }
      }
    },

    toogleCompleted() {
      this.showCompleted = !this.showCompleted;
    },
  },
};
</script>

<style scoped>
.css-ht5iojg {
  display: grid;
  grid-template-rows: 72px 1fr;
  position: relative;
  height: 100%;
}
h1 {
  background: #52d58c;
  color: #fff;
  padding: 1rem;
  text-align: center;
}
input[type="text"] {
  font-size: 1.5rem;
  height: 2.5rem;
}
.css-4kfi39s {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  background: #ecf4fd;
  padding: 40px;
  border-radius: 12px;
  width: 100%;
  max-width: 1200px;
}
@media screen and (min-width: 991px) {
  .css-4kfi39s {
    flex-direction: row;
    padding: 40px;
    border-radius: 12px;
  }
}
@media screen and (max-width: 991px) {
  .css-4kfi39s {
    flex-direction: column;
    padding: 24px;
    border-radius: 8px;
  }
}
.css-k3idldo {
  font-family: Roboto;
  font-size: 36px;
  font-weight: 500;
  color: #092f5b;
  line-height: 120%;
  max-width: 540px;
}

.css-gt44fo2 {
  font-family: "Quicksand";
  font-size: 15px;
  font-weight: 500;
  color: #092f5b;
  max-width: 540px;
}

.btn-primary {
  padding: 16px;
  background: #408fec;
  font-family: "Quicksand";
  font-size: 14px;
  font-weight: 600;
  color: #fff;
}
.css-h65hj64 {
  display: grid;
  grid-template-rows: 72px 1fr;
  position: relative;
  height: 100%;
}
.css-g5k433d {
  overflow-x: auto;
}
.css-g5hbgnt {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 24px;
  background: #fff;
  font-family: "Quicksand";
  font-size: 15px;
  font-weight: 500;
}
.css-gri3421 {
  padding: 14px 14px;
}
.css-gdo43fe {
}
.css-fegi3j3 {
  max-width: 540px;
}

.css-gir38gi {
  margin-top: 16px;
  color: #092f5b;
}

.css-gth52vv {
  padding: 20px 20px;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ccc;
}
.css-gitr4oh {
  font-family: "Roboto";
  font-size: 30px;
  font-weight: 400;
  color: #4b5258;
  line-height: 120%;
}

.css-gi4kk8d {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
}
.css-dwso32s {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px;
  font-family: "Quicksand";
  font-size: 12px;
  font-weight: 700;
  color: #1774e2;
  background: #ecf4fd;
  width: max-content;
  border-radius: 50px;
  line-height: 120%;
}
.css-rek3mro {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px;
  font-family: "Quicksand";
  font-size: 12px;
  font-weight: 700;
  color: #1774e2;
  background: #ecf4fd;
  width: max-content;
  border-radius: 50px;
  line-height: 120%;
}
.css-gu4rhboe {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 7px 8px 8px;
  font-family: "Quicksand";
  font-size: 12px;
  font-weight: 700;
  width: max-content;
  border-radius: 50px;
  line-height: 120%;
}
.css-gu4rhboe:hover {
  background: #edeeef;
}

.css-1f97miq {
  width: 100%;
  max-width: 1200px;
}
.css-gcv423g {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  overflow: hidden;
  border-radius: 20px;
  border: 1px solid #edeeef;
  box-shadow: 0 1px 3px rgba(50, 55, 59, 0.12);
}
.css-btru821 {
  position: relative;
  padding: 16px 32px;
  color: #4b5258;
  font-size: 20px;
  cursor: pointer;
}
.css-btru821:after {
  content: "";
  position: absolute;
  width: 28px;
  height: 28px;
  top: 18px;
  right: 32px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' focusable='false' class='chakra-icon css-7i4ecw' role='presentation'%3E%3Cpath d='M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z'%3E%3C/path%3E%3C/svg%3E");
  background-size: contain;
  background-repeat: no-repeat;
}

.css-gcv423g.open .css-bztbz65 {
  display: flex;
}
.css-bztbz65 {
  display: none;
  align-items: flex-start;
  flex-direction: column;
  padding: 16px 32px;
}
.css-zt55b55 {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 24px;
}
.css-cb5fgnhr4 {
  position: relative;
  min-width: 32px;
  height: 32px;
  border-radius: 50px;
  border: 1px solid #edeeef;
}
.accordion-row.checked .css-cb5fgnhr4 {
  background: #58bf13;
}
.accordion-row.checked .css-cb5fgnhr4:before {
  content: "";
  position: absolute;
  background: #58bf13;
  width: 18px;
  height: 18px;
  top: 6px;
  left: 6px;
  background-image: url("data:image/svg+xml,%3Csvg fill='white' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' focusable='false' class='chakra-icon css-jzgqt1' role='presentation'%3E%3Cpath d='M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z'%3E%3C/path%3E%3C/svg%3E");
  background-size: contain;
  background-repeat: no-repeat;
}
.css-bgt5bztr {
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  width: 100%;
}
.css-guow9dk {
  font-size: 14px;
  font-weight: 700;
}
.css-hjz465n4 {
  font-size: 14px;
  color: #a1a8ac;
}
.css-g54z5he {
  position: relative;
  height: 3px;
  padding: 0px 32px;
  background: #edeeef;
}
.css-g54z5he:before {
  content: "";
  height: 3px;
  background: #58bf13;
  position: absolute;
  left: 0px;
}
.css-g54z5he:not([data-width]):before {
  width: 0%;
}
.css-g54z5he[data-width="1"]:before {
  width: 100%;
}
.css-g54z5he[data-width="2"]:before {
  width: 50%;
}
.css-g54z5he[data-width="3"]:before {
  width: 33%;
}
.css-g54z5he[data-width="4"]:before {
  width: 25%;
}
.css-g54z5he[data-width="5"]:before {
  width: 20%;
}
.css-g54z5he[data-width="6"]:before {
  width: 16%;
}
.css-g54z5he[data-width="7"]:before {
  width: 14%;
}
.css-g54z5he[data-width="8"]:before {
  width: 12%;
}
.css-zt55b55 {
  position: relative;
  padding-right: 40px;
  width: 100%;
  cursor: pointer;
}
.css-zt55b55:hover {
}
.css-zt55b55:after {
  position: absolute;
  width: 24px;
  height: 24px;
  right: 0px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' focusable='false' class='chakra-icon css-1u4y5ht' role='presentation'%3E%3Cpath d='M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z'%3E%3C/path%3E%3C/svg%3E");
  background-size: contain;
  background-repeat: no-repeat;
}
.css-zt55b55:hover:after {
  content: "";
}

.css-z5h56fhg {
  margin-top: 60px;
}
.css-j7hgft5f {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}
.css-5hz5bree {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  flex-direction: row;
}

.css-h7jh67gg {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.css-hu6j76bg {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background: #f3f3f3;
  border-radius: 50px;
  cursor: pointer;
}
.css-abioh6xt {
  fill: #444;
  width: 20px;
  height: 20px;
}
.css-h6h65gv4 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background: #f3f3f3;
  border-radius: 50px;
  cursor: pointer;
}
.css-abindoxt {
  fill: #444;
  width: 20px;
  height: 20px;
}

.css-h6h6gvv4 {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  max-width: 1200px;
  padding-bottom: 24px;
  overflow-x: hidden;
}

.css-u6hgvtrv {
  display: flex;
  gap: 16px;
  transition: left 0.5s ease;
  position: relative;
  left: 0;
}

.css-j76j5h43 {
  border: 1px solid #eee;
  box-shadow: 1px 1px 2px #eee;
  border-radius: 12px;
  flex-shrink: 0;
  width: 22%;
}

.css-h6h6gvv4 > div:not(:first-child) {
  /*margin-left: 12px;*/
}

@media screen and (min-width: 1024px) {
  .css-j76j5h43 {
    /*min-width: 20%;*/
  }
}

.css-u78kj7jh {
  position: relative;
}
.css-h7j4g54f {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  top: 16px;
  left: 16px;
  background: #f777c0;
  color: #fff;
  border-radius: 50px;
  font-size: 14px;
  font-weight: 700;
  z-index: 10;
}

.css-bz6b5zv4 {
  position: relative;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  height: 160px;
  background-image: url(https://dash-v2.smartsuppcdn.com/assets/images/academy/installation.webp);
  background-size: cover;
  background-repeat: no-repeat;
}
.css-h5h5hg4f {
  position: relative;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  height: 160px;
  background-image: url(https://dash-v2.smartsuppcdn.com/assets/images/academy/chatbox-text.webp);
  background-size: cover;
  background-repeat: no-repeat;
}
.css-h6v5h5g5 {
  position: relative;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  height: 160px;
  background-image: url(https://dash-v2.smartsuppcdn.com/assets/images/academy/auto-messages.webp);
  background-size: cover;
  background-repeat: no-repeat;
}
.css-j7iu6gzf {
  position: relative;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  height: 160px;
  background-image: url(https://dash-v2.smartsuppcdn.com/assets/images/academy/app.webp);
  background-size: cover;
  background-repeat: no-repeat;
}
.css-hu7hgzft {
  position: relative;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  height: 160px;
  background-image: url(https://dash-v2.smartsuppcdn.com/assets/images/academy/chatbot-assistant.webp);
  background-size: cover;
  background-repeat: no-repeat;
}

.css-h5hg64f5 {
  padding: 16px;
}
.css-z6g5frcf {
  font-size: 16px;
  font-weight: 700;
  color: #000;
  line-height: 1.2;
}
.css-h6hgvtv6 {
  font-size: 14px;
  font-weight: 400;
  color: #7e8991;
  line-height: 1.2;
}
.css-h6g54cfg {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  padding: 16px;
}
.css-h76hg5tf {
}
.css-7hg6t5hf {
}
</style>
