<template>
  <div class="agent">
    <textarea
      v-model="message"
      placeholder="Type a message..."
      @input="handleTyping"
      @keydown.enter="sendMessage"
    ></textarea>
    <button @click="sendMessage">Send</button>
    <div v-if="isTyping">The agent is typing...</div>
  </div>
</template>

<script>
import io from "socket.io-client";

export default {
  data() {
    return {
      socket: null,
      message: "",
      isTyping: false,
      userToken: "gjriegirgtrhtrhtrhg",
      clientToken: "cgpkXwy3fE6f9ZrDWuXI6Wuz", // ez csak azért kell, hogy definiáljunk melyik látogatónak menjen el az üzenet
      agentToken: "xl3gH7kLb21", // Replace with the actual value
    };
  },
  mounted() {
    this.initializeSocket();
  },
  methods: {
    initializeSocket() {
      this.socket = io("https://socket.octagent.com:3000", {
        withCredentials: true,
        extraHeaders: {
          "Access-Control-Allow-Origin": "*",
        },
      });

      this.socket.on("connect", () => {
        this.socket.emit("connected", {
          agentToken: this.agentToken,
          userToken: this.userToken,
        });
      });

      this.socket.on("typing", () => {
        console.log("client typing");
        this.isTyping = true;
        setTimeout(() => (this.isTyping = false), 5000);
      });

      this.socket.on("message", (message) => {
        console.log("received message from client: " + message);
        this.receivedMessages.push(message);
      });
    },

    handleTyping() {
      if (this.message.trim()) {
        console.log("agent typing");
        this.socket.emit("typing", {
          agenToken: this.agentToken,
          clientToken: this.clientToken,
        });
      }
    },
    sendMessage() {
      if (this.message.trim()) {
        this.socket.emit("message", {
          agentToken: this.agentToken,
          clientToken: this.clientToken,
          message: this.message,
        });
        this.message = "";
      }
    },
    setCookie(name, value, days) {
      let expires = "";
      if (days) {
        let date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = "; expires=" + date.toUTCString();
      }
      document.cookie =
        name +
        "=" +
        (value || "") +
        expires +
        "; path=/; SameSite=None; Secure";
    },
    getCookie(name) {
      let nameEQ = name + "=";
      let ca = document.cookie.split(";");
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === " ") c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0)
          return c.substring(nameEQ.length, c.length);
      }
      return null;
    },
  },
};
</script>

<style scoped>
.agent {
  display: flex;
  flex-direction: column;
}
textarea {
  margin-bottom: 10px;
}
</style>
