<template>
  <div id="app">
    <div v-if="currentLayout === 'default'" id="app-default-layout">
      <nav></nav>
      <div class="content">
        <router-view />
      </div>
    </div>

    <div v-if="currentLayout === 'app'" id="app-layout">
      <div class="css-g7reo3f">
        <div class="css-gk4igk3">
          <div class="css-gt54jjt">
            You haven't completed the installation yet. To make your live chat
            running, connect Smartsupp with your website.
          </div>
          <div class="css-g548ikd">
            <button class="css-go542fof">Install Smartsupp</button>
          </div>
        </div>
      </div>
      <nav>
        <div class="menu-item avatar-photo online">
          <div class="status"></div>
          <img
            src="https://dash-v2.smartsuppcdn.com/assets/icons/svg/user.svg"
          />
        </div>

        <ul class="menu-top">
          <router-link to="/">
            <div
              class="menu-item home"
              @click="setActive('home')"
              :class="{ active: activeItem === 'home' }"
            >
              <svg viewBox="0 0 24 24" fill>
                <path d="M10,20V14H14V20H19V12H22L12,3L2,12H5V20H10Z"></path>
              </svg></div
          ></router-link>

          <router-link to="/conversations">
            <div
              class="menu-item conversations"
              @click="setActive('conversations')"
              :class="{ active: activeItem === 'conversations' }"
            >
              <svg viewBox="0 0 24 24" fill>
                <path
                  d="M10.36,3h0C15,3,18.79,6.06,18.79,9.83S15,16.66,10.36,16.66A9.54,9.54,0,0,1,9,16.57a8.08,8.08,0,0,1-6,2.18v-.44a3.27,3.27,0,0,0,2-2.7,3.87,3.87,0,0,0,0-.48,6.41,6.41,0,0,1-3.13-5.3C1.92,6.06,5.7,3,10.36,3Zm9.07,15.31A2.61,2.61,0,0,0,21,20.62V21a6.7,6.7,0,0,1-5-1.87,8.74,8.74,0,0,1-1.14.08A8.51,8.51,0,0,1,10.4,18a10.77,10.77,0,0,0,6.76-2.3,8.14,8.14,0,0,0,2.15-2.59,7,7,0,0,0,.82-3.27c0-.19,0-.37,0-.55a5.31,5.31,0,0,1,2.06,4.07,5.5,5.5,0,0,1-2.71,4.55A2.73,2.73,0,0,0,19.43,18.31Z"
                ></path>
              </svg>
            </div>
          </router-link>

          <router-link to="/automations">
            <div
              class="menu-item automations"
              @click="setActive('automations')"
              :class="{ active: activeItem === 'automations' }"
            >
              <svg viewBox="0 0 24 24" fill>
                <path
                  d="M20.579 9.062a5.946 5.946 0 0 0-5.759-4.48H14v-.744A2.337 2.337 0 0 0 12 2a2.337 2.337 0 0 0-2 1.838v.744H8.871a5.946 5.946 0 0 0-5.759 4.48C1.761 9.157 1 10 1 11.646v5.206a2.527 2.527 0 0 0 2.294 2.6 2.549 2.549 0 0 0 .827-.147 5.934 5.934 0 0 0 4.75 2.378h5.949a5.933 5.933 0 0 0 4.75-2.378 2.549 2.549 0 0 0 .827.147 2.6 2.6 0 0 0 2.6-2.6v-5.206a2.6 2.6 0 0 0-2.418-2.584zM10.358 16.48a1.487 1.487 0 1 1-2.974 0v-1.487a1.487 1.487 0 1 1 2.974 0zm5.949 0a1.487 1.487 0 1 1-2.974 0v-1.487a1.487 1.487 0 1 1 2.974 0z"
                ></path>
              </svg>
            </div>
          </router-link>

          <router-link to="/customers">
            <div
              class="menu-item customers"
              @click="setActive('customers')"
              :class="{ active: activeItem === 'customers' }"
            >
              <svg viewBox="0 0 24 24" fill>
                <path
                  d="M16 17V19H2V17S2 13 9 13 16 17 16 17M12.5 7.5A3.5 3.5 0 1 0 9 11A3.5 3.5 0 0 0 12.5 7.5M15.94 13A5.32 5.32 0 0 1 18 17V19H22V17S22 13.37 15.94 13M15 4A3.39 3.39 0 0 0 13.07 4.59A5 5 0 0 1 13.07 10.41A3.39 3.39 0 0 0 15 11A3.5 3.5 0 0 0 15 4Z"
                ></path>
              </svg>
            </div>
          </router-link>

          <router-link to="/statistics">
            <div
              class="menu-item statistics"
              @click="setActive('statistics')"
              :class="{ active: activeItem === 'statistics' }"
            >
              <svg viewBox="0 0 24 24" fill>
                <path
                  d="M21,4H16V20H21V4M14,9H9V20H14V9M7,14H2V20H7V14Z"
                ></path>
              </svg>
            </div>
          </router-link>
        </ul>

        <ul class="menu-bottom">
          <router-link to="/conversations">
            <div
              class="menu-item settings"
              @click="setActive('settings')"
              :class="{ active: activeItem === 'settings' }"
            >
              <svg viewBox="0 0 24 24" fill>
                <path
                  d="M12,15.5A3.5,3.5 0 0,1 8.5,12A3.5,3.5 0 0,1 12,8.5A3.5,3.5 0 0,1 15.5,12A3.5,3.5 0 0,1 12,15.5M19.43,12.97C19.47,12.65 19.5,12.33 19.5,12C19.5,11.67 19.47,11.34 19.43,11L21.54,9.37C21.73,9.22 21.78,8.95 21.66,8.73L19.66,5.27C19.54,5.05 19.27,4.96 19.05,5.05L16.56,6.05C16.04,5.66 15.5,5.32 14.87,5.07L14.5,2.42C14.46,2.18 14.25,2 14,2H10C9.75,2 9.54,2.18 9.5,2.42L9.13,5.07C8.5,5.32 7.96,5.66 7.44,6.05L4.95,5.05C4.73,4.96 4.46,5.05 4.34,5.27L2.34,8.73C2.21,8.95 2.27,9.22 2.46,9.37L4.57,11C4.53,11.34 4.5,11.67 4.5,12C4.5,12.33 4.53,12.65 4.57,12.97L2.46,14.63C2.27,14.78 2.21,15.05 2.34,15.27L4.34,18.73C4.46,18.95 4.73,19.03 4.95,18.95L7.44,17.94C7.96,18.34 8.5,18.68 9.13,18.93L9.5,21.58C9.54,21.82 9.75,22 10,22H14C14.25,22 14.46,21.82 14.5,21.58L14.87,18.93C15.5,18.67 16.04,18.34 16.56,17.94L19.05,18.95C19.27,19.03 19.54,18.95 19.66,18.73L21.66,15.27C21.78,15.05 21.73,14.78 21.54,14.63L19.43,12.97Z"
                ></path>
              </svg>
            </div>
          </router-link>
          <router-link to="/conversations">
            <div
              class="menu-item help"
              @click="setActive('help')"
              :class="{ active: activeItem === 'help' }"
            >
              <svg viewBox="0 0 24 24" fill>
                <path
                  d="M15.07,11.25L14.17,12.17C13.45,12.89 13,13.5 13,15H11V14.5C11,13.39 11.45,12.39 12.17,11.67L13.41,10.41C13.78,10.05 14,9.55 14,9C14,7.89 13.1,7 12,7A2,2 0 0,0 10,9H8A4,4 0 0,1 12,5A4,4 0 0,1 16,9C16,9.88 15.64,10.67 15.07,11.25M13,19H11V17H13M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12C22,6.47 17.5,2 12,2Z"
                ></path>
              </svg>
            </div>
          </router-link>
          <div class="menu-item logo">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 113.387 113.386"
              class="css-5afk8a"
            >
              <g>
                <path
                  fill="#4088E6"
                  d="M35.563,31.458h1.484c5.234,0,10.029,2.221,14.388,6.664c3.354,3.679,5.231,8.472,5.262,13.45v0.341 c0,6.848-3.039,12.478-9.124,16.893c-3.297,2.188-7.162,3.354-11.12,3.352h-0.338c-3.25-0.04-6.448-0.841-9.338-2.333v22.534 l-0.129,0.17h-0.04H16.168l-0.169-0.127v-0.043V51.023c0-5.095,2.176-9.862,6.534-14.304 C26.776,33.211,31.121,31.458,35.563,31.458 M36.2,42.322h-0.383c-3.142,0-5.787,1.669-7.937,5.008 c-0.678,1.405-1.055,2.938-1.104,4.498c0,3.706,1.838,6.579,5.519,8.616c1.204,0.537,2.504,0.826,3.819,0.85h0.424 c3.508,0,6.27-1.727,8.276-5.18c0.665-1.27,1.012-2.682,1.016-4.115v-0.427c0-3.45-1.723-6.222-5.176-8.317 c-1.18-0.565-2.467-0.884-3.779-0.933h-0.423l-0.083-0.063v-0.022L36.2,42.322z"
                ></path>
                <path
                  fill="#4088E6"
                  d="M80.254,31.458h1.484c5.234,0,10.029,2.221,14.388,6.664c3.354,3.679,5.229,8.472,5.262,13.45v0.341 c0,6.848-3.043,12.478-9.124,16.893c-3.297,2.188-7.162,3.354-11.12,3.352h-0.338c-3.254-0.04-6.448-0.841-9.338-2.333v22.534 l-0.126,0.17h-0.043H60.859l-0.172-0.127v-0.043V51.023c0-5.095,2.179-9.862,6.537-14.304 C71.468,33.211,75.81,31.458,80.254,31.458 M80.889,42.322h-0.384c-3.138,0-5.787,1.669-7.933,5.008 c-0.682,1.405-1.055,2.938-1.104,4.498c0,3.706,1.838,6.579,5.519,8.616c1.2,0.537,2.5,0.826,3.819,0.85h0.424 c3.508,0,6.266-1.727,8.276-5.18c0.662-1.27,1.012-2.682,1.016-4.115v-0.427c0-3.45-1.723-6.222-5.176-8.317 c-1.18-0.565-2.467-0.884-3.779-0.933h-0.423l-0.083-0.063v-0.022L80.889,42.322z"
                ></path>
                <path
                  fill="#FFFFFF"
                  d="M36.369,37.758c7.394,0,13.39,5.995,13.39,13.389c0,7.396-5.996,13.391-13.39,13.391 s-13.39-5.995-13.39-13.391C22.979,43.753,28.976,37.758,36.369,37.758"
                ></path>
                <path
                  fill="#2C3C45"
                  d="M35.973,42.417c-0.92,0-1.829,0.193-2.666,0.567c1.634,0.32,2.699,1.904,2.378,3.538 c-0.317,1.635-1.901,2.701-3.538,2.381c-1.135-0.222-2.044-1.076-2.338-2.195c-1.26,3.405,0.479,7.188,3.886,8.448 c3.405,1.258,7.186-0.479,8.445-3.886c1.26-3.404-0.479-7.187-3.882-8.446C37.526,42.554,36.753,42.417,35.973,42.417"
                ></path>
                <path
                  fill="#FFFFFF"
                  d="M80.657,37.758c7.397,0,13.389,5.995,13.389,13.389c0,7.396-5.991,13.391-13.389,13.391 c-7.395,0-13.39-5.995-13.39-13.391C67.268,43.753,73.263,37.758,80.657,37.758"
                ></path>
                <path
                  fill="#2C3C45"
                  d="M80.261,42.417c-0.92,0-1.826,0.193-2.666,0.567c1.634,0.32,2.698,1.904,2.381,3.538 c-0.32,1.635-1.904,2.701-3.538,2.381c-1.138-0.222-2.043-1.076-2.338-2.195c-1.26,3.405,0.479,7.188,3.882,8.448 c3.406,1.258,7.187-0.479,8.449-3.886c1.26-3.404-0.479-7.187-3.886-8.446C81.814,42.554,81.041,42.417,80.261,42.417"
                ></path>
              </g>
            </svg>
          </div>
        </ul>
      </nav>

      <div class="content">
        <router-view />
      </div>
    </div>

    <div id="nav" style="display: none">
      <router-link to="/">Home</router-link> |
      <router-link to="/stats">Stats</router-link> |
      <router-link to="/login" v-if="!loggedIn">Login</router-link>
      <router-link to="/logout" v-if="loggedIn">Logout</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      activeItem: null,
      loggedIn: false,
    };
  },
  computed: {
    currentLayout() {
      return this.$route.meta.layout || "default";
    },
    isLoggedIn() {
      return !!localStorage.getItem("token");
    },
  },
  methods: {
    setActive(itemName) {
      if (this.isLoggedIn) {
        this.activeItem = itemName;
      }
    },
  },
  created() {
    this.$store.dispatch("getTasks");
  },
};
</script>

<style>
@import url("./assets/foundation.min.css");
@import url("./assets/foundation-icons.css");

body {
  overflow: hidden;
}

.page-content {
  grid-area: content;
  overflow: hidden;
}

#app {
  background: #444;
  color: #485258;
  font-family: "Quicksand", sans-sarif;
}

#app-layout {
  display: grid;
  grid-template: "upsell upsell" min-content "sidebar content" 1fr / 72px 1fr;
  height: 100vh;
}

.css-g7reo3f {
  grid-area: upsell;
}

.css-gk4igk3 {
  width: 100%;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  padding: 12px 12px;
  background: rgb(254, 235, 200);
  font-size: 14px;
  justify-content: space-between;
}

.css-gt54jjt {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.css-g548ikd {
}

.css-go542fof {
  display: inline-flex;
  justify-content: center;
  position: relative;
  white-space: nowrap;
  line-height: 100%;
  border-radius: 4px;
  font-size: 12px;
  padding: 10px;
  font-weight: 700;
  background: #58bf13;
  color: #fff;
}

nav {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #302f4a;
  z-index: 12;
  overflow: hidden;
  grid-area: sidebar;
}

nav .avatar-photo {
  margin-top: 10px;
}

nav .avatar-photo img {
  width: 36px;
  height: 36px;
  border-radius: 50px;
}

nav .avatar-photo.online .status {
  position: absolute;
  background: #58bf13;
  width: 12px;
  height: 12px;
  margin-top: 26px;
  margin-left: 28px;
  border-radius: 50px;
  border: 3px solid #302f4a;
}

.menu-item {
  display: flex;
  height: 50px;
  width: 50px;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  cursor: pointer;
  margin-bottom: 10px;
}

.menu-item.active {
  background: #408fec;
}

.menu-item:not(.active):hover {
  background: #3d3c57;
}

.menu-item svg {
  width: 24px;
  height: 24px;
}

.menu-item svg[fill] path {
  fill: #9da1c2;
}

.menu-item.active svg[fill] path {
  fill: #fff;
}

.menu-top {
  margin-top: 0px;
}

.menu-bottom {
  margin-top: auto;
}

.content {
  background: #999;
  grid-area: content;
  overflow: hidden;
}
</style>
